export const ProjectsData = [
	{
		title: 'San Bernardo',
		body: "TACOS!! I think I've overdosed on tacos. I'd have to say, those tacos are on fleek.",
		imagen: require('../../assets/images/project-1.jpg'),
	},
	{
		title: 'Nos',
		body: "Every day is taco ipsum tuesday. I think I've overdosed on tacos. Give me all the tacos, immediately.",
		imagen: require('../../assets/images/project-2.jpg'),
	},
	{
		title: 'Calera de Tango',
		body: "I think I've overdosed on tacos. I'd have to say, those tacos are on fleek.",
		imagen: require('../../assets/images/project-3.jpg'),
	},
	{
		title: 'Buin',
		body: "I think I've overdosed on tacos. I'd have to say, those tacos are on fleek.",
		imagen: require('../../assets/images/project-1.jpg'),
	},
];
