export const MenuData = [
	{
		title: 'Nosotros',
		url: '#nosotros',
		cName: 'navbar-menu__link',
	},
	{
		title: 'Projectos',
		url: '#projectos',
		cName: 'navbar-menu__link',
	},
	{
		title: 'Contacto',
		url: '#contacto',
		cName: 'navbar-menu__link',
	},
];
